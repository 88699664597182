var customSelect = document.querySelectorAll('.select-custom');

customSelect.forEach(function(select){
	var input 	= select.querySelector('input');
  var campo 	= select.querySelector('.campo');
  var output  = select.querySelector('.output');
  var options = select.querySelectorAll('.options li');
  
  campo.addEventListener('click', function(){
  	select.classList.toggle('open');
  });
  
  options.forEach(function(option){
  	option.addEventListener('click', function(){
    	var value = this.getAttribute('data-value');
      var text	= this.innerText;
      
      input.value 		= value;
      output.innerText = text;
      select.classList.remove('open');
      
      options.forEach(function(option){
      	option.classList.remove('active');
      });
      
      option.setAttribute('class','active');
    })
  });
});