$('[data-toggle="search"]').click(function(){
	var target = $(this).data('target');

	$(target).addClass('show');
	$('.bg-busca').fadeIn(300);
});

$('.bg-busca').click(function(){
	$('#campo-busca').removeClass('show');

	$(this).fadeOut(300);
});