$('[data-toggle="menu"]').click(function(e){
	e.preventDefault();

	var target = $(this).data('target');

	$(target).toggleClass('open-menu');

	if(midiaSize != 'xl') {
		var aux = document.createElement('div');

		$(aux).addClass('aux');

		$(aux).click(function(){
			$(target).removeClass('open-menu');

			$('.aux').fadeOut(600, function(){
				$(this).remove();
			});
		});

		document.body.appendChild(aux);
		$(aux).fadeIn(600);
	}
});